<template>
  <esmp-scale :title="title" :options="options" />
</template>

<script>
export default {
  name: 'ScaleView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    title() {
      return this.settings.find((el) => el.techName === 'name').value;
    },
    options() {
      const scaleValues = this.settings.find((el) => el.techName === 'scaleValues').values;
      return scaleValues.map((i, ind) => ({
        text: i.name,
        value: i?.extendedValues[0]?.value || ind,
      }));
    },
  },
};
</script>
